/* this file is transformed by vux-loader */
/* eslint-disable */
import "core-js/modules/es.array.push.js";
import axios from "axios";
import LCFQheader from "../../LCFQheader";
import { RxUtil } from "@/assets/util.js";
import { timesFnt, getTextLst, format } from "@/assets/app.js";
export default {
  name: "lcfqYyqy",
  components: {
    LCFQheader
  },
  data() {
    return {
      headTit: "新建申请",
      tit: "印章启用申请",
      oneft: "/index",
      userID: "",
      solId: "",
      actDefId: "",
      boDefId: "",
      formKey: "",
      //userSelect
      single: false,
      allowSelect: true,
      showUserDialog: false,
      showGroupDialog: false,
      nodeGroupVm: {},
      showYzmc: false,
      showQysj: false,
      minDate: new Date(2020, 0, 1),
      currentDate: new Date(),
      yzmcList: [],
      yzList: [],
      data: {
        yyr: "",
        qybm: "",
        fj: [],
        sqbgr: "",
        sqfzr: "",
        jsbgr: "",
        jszfr: ""
      },
      fjsc: []
    };
  },
  mounted() {
    this.initBySolInstId();
    this.cgORxj();
  },
  methods: {
    async cgORxj() {
      let instId = this.$route.params.instId;
      // console.log(typeof(busad))
      if (instId != 0) {
        this.headTit = "我的草稿";
        this.oneft = "/myDrafts";
        let solId = this.$route.params.solId;
        var url = _baseUrl + "/mobile/bpm/startForm.do?solId=" + solId + "&instId=" + instId;
        this.$ajax.post(url).then(res => {
          let jsonData = res.data.formModels[0].jsonData;
          this.data = jsonData;
          let sqbgr = [{
            id: jsonData.jsbgr,
            text: jsonData.jsbgr_name
          }];
          this.data.sqbgr = JSON.stringify(sqbgr);
          let sqfzr = [{
            id: jsonData.sqfzr,
            text: jsonData.sqfzr_name
          }];
          this.data.sqfzr = JSON.stringify(sqfzr);
          let jsbgr = [{
            id: jsonData.jsbgr,
            text: jsonData.jsbgr_name
          }];
          this.data.jsbgr = JSON.stringify(jsbgr);
          let jszfr = [{
            id: jsonData.jszfr,
            text: jsonData.jszfr_name
          }];
          this.data.jszfr = JSON.stringify(jszfr);
        });
      } else {
        this.getUser();
      }
    },
    initBySolInstId() {
      let userId = RxUtil.getCache("userID");
      let userName = RxUtil.getCache("userName");
      let userList = [{
        id: userId,
        text: userName
      }];
      let userObj = JSON.stringify(userList);
      this.data.sqbgr = this.data.sqfzr = this.data.jsbgr = this.data.jszfr = userObj;
      let solId = this.$route.params.solId;
      var url = _baseUrl + "/mobile/bpm/startForm.do?solId=" + solId;
      this.$ajax.post(url).then(response => {
        this.solId = response.data.bpmSolution.solId;
        this.actDefId = response.data.bpmSolution.actDefId;
        this.boDefId = response.data.formModels[0].boDefId;
        this.formKey = response.data.formModels[0].formKey;
        let bnID = response.data.formModels[0].jsonData.qybm;
        let bnName = response.data.formModels[0].jsonData.qybm_name;
        let bnList = [{
          id: bnID,
          text: bnName
        }];
        this.data.qybm = JSON.stringify(bnList);
      });
      var yzUrl = _baseUrl + "/sys/db/sysSqlCustomQuery/queryForJson_cxkz.do";
      this.$ajax.post(yzUrl).then(res => {
        // let yzmcList = [];
        this.yzList = res.data.data;
        this.yzList.map(item => {
          this.yzmcList.push(item.YZMC);
        });
      });
    },
    //获取用户信息
    getUser() {
      var userID = RxUtil.getCache("userID");
      this.data.yyr = RxUtil.getCache("userName");
    },
    //选人
    selectUserDialog(vm) {
      this.showUserDialog = true;
      this.$refs.sysUser.search();
      // 设置选择用户。
      this.$refs.sysUser.setValue("");
      //设置选中的rx-box 控件，用于传递数据。
      this.nodeUserVm = vm;
    },
    selectUser(data) {
      var list = [];
      for (var i = 0; i < data.length; i++) {
        var obj = data[i];
        var o = {};
        o.id = obj.userId;
        o.text = obj.fullname;
        list.push(o);
      }
      var json = JSON.stringify(list);
      this.nodeUserVm.setValue(json);
      this.showUserDialog = false;
    },
    selectGroupDialog(vm) {
      this.showGroupDialog = true;
      this.$refs.sysGroup.type = "group";
      this.$refs.sysGroup.search();
      // 设置选择用户。
      this.$refs.sysGroup.setValue("");
      //设置选中的rx-box 控件，用于传递数据。
      this.nodeGroupVm = vm;
    },
    selectGroup(data) {
      var list = [];
      for (var i = 0; i < data.length; i++) {
        var obj = data[i];
        var o = {};
        var test = {};
        o.id = obj.groupId;
        o.text = obj.name;
        list.push(o);
      }
      var json = JSON.stringify(list);
      this.nodeGroupVm.setValue(json);
      this.showGroupDialog = false;
    },
    //下拉
    onYzmc(value) {
      this.data.sqyzmc_name = value;
      // this.yzList.map((item)=>{
      //   if (value == item.YZMC) {
      //     this.data.qybm = item.SQBM;
      //   }
      // })
      this.showYzmc = false;
    },
    onQysj(timestamp) {
      this.data.qysj = format(timestamp);
      this.showQysj = false;
    },
    //上传附件
    beforeRead(file) {
      if (this.data.fj.length > 0) {
        let fjStr = this.onFile();
        if (fjStr.indexOf(file.name) == -1) {
          return true;
        } else {
          return false;
        }
      } else {
        return true;
      }
    },
    afterRead(file) {
      let formData = new FormData();
      formData.append("from", "SELF");
      formData.append("types", "");
      formData.append("sysTreeId", "");
      formData.append("id", "WU_FILE_0");
      formData.append("name", file.file.name);
      formData.append("type", file.file.type);
      formData.append("lastModifiedDate", file.file.lastModifiedDate);
      formData.append("size", file.file.size);
      formData.append("file", file.file);
      var fjObj;
      let formDataUrl = _baseUrl + "/sys/core/file/upload.do";
      this.$ajax.post(formDataUrl, formData).then(res => {
        fjObj = {};
        fjObj.fileId = res.data.data[0].fileId;
        fjObj.fileName = res.data.data[0].fileName;
        fjObj.totalBytes = res.data.data[0].totalBytes;
        this.fjsc.push(fjObj);
      });
    },
    beforeDelete(file) {
      if (this.data.fj.length > 1) {
        for (let i = 0; i < this.fjsc.length; i++) {
          if (file.file.name == this.fj[i].fileName) {
            this.fj.splice(i, 1);
          }
        }
        return true;
      } else {
        return true;
      }
    },
    onFile() {
      let fjObj = {};
      let fj = [];
      this.data.fj.map(item => {
        fjObj = {};
        fjObj.fileId = item.file.lastModified;
        fjObj.fileName = item.file.name;
        fjObj.totalBytes = item.file.size;
        fj.push(fjObj);
      });
      return JSON.stringify(fj);
    },
    upDatas() {
      let qybm = JSON.parse(this.data.qybm);
      let sqbgr = JSON.parse(this.data.sqbgr);
      let sqfzr = JSON.parse(this.data.sqfzr);
      let jsbgr = JSON.parse(this.data.jsbgr);
      let jszfr = JSON.parse(this.data.jszfr);
      let fj = JSON.stringify(this.fjsc);
      var jsonDataObj = {
        bos: [{
          boDefId: this.boDefId,
          formKey: this.formKey,
          data: {
            sqyzmc: this.data.sqyzmc_name,
            sqyzmc_name: this.data.sqyzmc_name,
            qybm: qybm[0].id,
            qybm_name: qybm[0].text,
            qysj: this.data.qysj,
            qyyy: this.data.qyyy,
            sqbgr: sqbgr[0].id,
            sqbgr_name: sqbgr[0].text,
            sqfzr: sqfzr[0].id,
            sqfzr_name: sqfzr[0].text,
            jsbgr: jsbgr[0].id,
            jsbgr_name: jsbgr[0].text,
            jszfr: jszfr[0].id,
            jszfr_name: jszfr[0].text,
            bz: this.data.bz,
            fj: fj
          }
        }]
      };
      var jsonData = JSON.stringify(jsonDataObj);
      var params = new FormData();
      params.append("solId", this.solId);
      params.append("bpmInstId", "");
      params.append("jsonData", jsonData);
      return params;
    },
    onSubmit() {
      var params = this.upDatas();
      var url = _baseUrl + "/bpm/core/bpmInst/startProcess.do";
      this.$dialog.loading.open('正在启动流程,请稍候...');
      this.$ajax.post(url, params).then(res => {
        this.$dialog.loading.close();
        var data = res.data;
        if (data.success) {
          this.$store.commit('cleanInstData');
          this.successToast("启动成功！", 1000, 'index', {
            from: 'startForm'
          });
        } else {
          this.errorToast("启动失败！请检查填写信息是否完整", "1800");
        }
      });
    },
    onPreservation() {
      var params = this.upDatas();
      var url = _baseUrl + "/bpm/core/bpmInst/saveDraft.do";
      this.$dialog.loading.open('正在保存草稿,请稍候...');
      this.$ajax.post(url, params).then(res => {
        this.$dialog.loading.close();
        var data = res.data;
        if (data.success) {
          this.$store.commit('cleanInstData');
          this.successToast("保存成功！", 1000, 'index', {
            from: 'startForm'
          });
        } else {
          this.errorToast("保存失败！请检查填写信息是否完整", "1800");
        }
      });
    },
    onLct() {
      this.$router.push({
        name: "procImage",
        params: {
          id: this.actDefId,
          type: 'start'
        }
      });
    }
  }
};